import * as React from "react"
import appInsights from '../../../../AppInsights';
import AboutUs from "../../../route/about_us_en"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <AboutUs props={props} />
      </React.Fragment>
  )
} 

export default IndexPage
