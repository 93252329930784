import React, { Component } from "react";


import Layout from "../components/layou_en"
import Banner from "../components/others/banner"
import Timeline from "../components/others/Timeline"

import ReactMarkdown from 'react-markdown'

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

import { StaticQuery, graphql } from "gatsby"
import Figure from "../components/others/figure"
import Certificado from "../components/others/certificado"

const objectGraphql = graphql`
{
  allStrapiHome(filter: {locale: {eq: "en"}}) {
    edges {
      node {
        
       cetificado {
         id
         logo {
           url
         }
         single_img {
           img {
             url
           }
         }
         titulo
       }
      
      }
    }
  }
  strapiNosotros(locale: {eq: "en"}) {
    Banner {
      fondo {
        url
      }
      id
      titulo
      url {
        enlace
        id
        titulo
      }
    }
    Empresa {
      descripcion
      id
      img {
        id
        img {
          url
        }
      }
      titulo
    }
    Historia {
      fecha {
        descripcion
        evento
        fecha
        id
      }
      id
      titulo
    }
    estructura {
      directorio {
        cargo
        id
        usuario
      }
      gerencia {
        cargo
        id
        pdf {
          url
        }
        usuario
      }
      titulo
      img {
        url
      }
    }
    metas {
      descripcion
      id
      titulo
    }
    pilares {
      titulo
      id
      Lista {
        descripcion
        id
        titulo
        fondo {
          url
        }
        icono {
          url
        }
      }
    }
  }
}
`


class AboutUs extends Component {

  render() {


    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>
            <Layout props={this.props.props}>
              <Banner banner={data.strapiNosotros.Banner} />
              <section className="padding-section-int">
                <div className="container">

                  <div className="row margin-b-80">
                    <div className="col-md-5">
                      <div>
                        <h2>{data.strapiNosotros.Empresa.titulo}</h2>

                        <ReactMarkdown>{data.strapiNosotros.Empresa.descripcion}</ReactMarkdown>
                      </div>
                    </div>
                    <div className="col-md-7 text-center">
                      <p><img src={data.strapiNosotros.Empresa.img[0].img[0].url} alt={data.strapiNosotros.Empresa.img[0].img[0].url} className="img-fluid" />
                      </p>
                    </div>
                  </div>

                  <div className="row margin-b-60">
                    <div className="col-md-12">
                      {data.strapiNosotros.metas.map((item, index) => {
                        return (
                          <div key={index} className="col-md-8 offset-md-2">
                            <h2 className="rtecenter">{item.titulo}</h2>
                            <p className="rtecenter">{item.descripcion}</p>
                            <br />
                            <br />
                            <br />
                            <br />
                          </div>
                        )
                      })}


                    </div>
                  </div>
                </div>


              </section>
              <section>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 titulo-center text-center">
                      <h3>{data.strapiNosotros.pilares.titulo}</h3>
                    </div>
                  </div>

                </div>
                <Figure figure={data.strapiNosotros.pilares.Lista} />
                <div className="container">
                  <div className="row margin-b-80 nosotros-historias">
                    <div className="col-md-12 text-center">
                      <h3>{data.strapiNosotros.Historia.titulo}</h3>
                    </div>
                    <div className="col-md-8 offset-md-2">
                      <Timeline time={data.strapiNosotros.Historia.fecha} />
                    </div>
                  </div>
                  <div className="row margin-b-80">
                    <div className="col-md-12 text-center margin-b-40">
                      <h3>{data.strapiNosotros.estructura.titulo}</h3>
                    </div>
                    <div className="col-md-4">
                      <p><strong>Board</strong></p>

                      <ul className="listado">
                        {data.strapiNosotros.estructura.directorio.map((item, index) => {
                          return (
                            <li key={index}>
                            {item.pdf ? <a href={item.pdf.url} target="_blank" rel="noreferrer"> {item.usuario}</a> : item.usuario}
                            <br />
                            <i>{item.cargo}</i></li>
                          )
                        })}


                      </ul>
                    </div>
                    <div className="col-md-4">
                      <p><strong>Gerencia</strong></p>

                      <ul className="listado">
                        {data.strapiNosotros.estructura.gerencia.map((item, index) => {
                          return (
                            <li key={index}>
                            {item.pdf ? <a href={item.pdf.url} target="_blank" rel="noreferrer"> {item.usuario}</a> : item.usuario}
                            <br />
                            <i>{item.cargo}</i></li>
                          )
                        })}

                      </ul>
                    </div>
                    <div className="col-md-4">
                      <p><img src={data.strapiNosotros.estructura.img.url} className="img-fluid" alt="" /></p>
                    </div>
                  </div>

                </div>
              </section>
              <section>
                <ScrollAnimation animateIn="fadeInUp" ><Certificado certificado={data.allStrapiHome.edges[0].node.cetificado} />
                </ScrollAnimation >
              </section>
            </Layout>
          </ React.Fragment>
        )}
      />
    )
  }
}

export default AboutUs
